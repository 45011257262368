<template>
  <div id="">
    <template v-if="!$route.path.includes('download') && !$route.path.includes('zdyhwy') && !$route.path.includes('zdyhwya')">
      <div class="footer-container" v-if="!isMobile">
        <div class="footer m-auto">
          <div class="footer-l">
            <svg-icon :url="footerInfo.logo" class-name="logo" />
            <div
              class="footer-l-base"
              v-for="(item, index) in footerInfo.base"
              :key="index"
            >
              <span class="footer-l-base-title">{{ item.key }}</span>
              <span class="footer-l-base-desc">{{ item.value }}</span>
            </div>
            <a class="footer-l-xml" href="https://www.shijiaoshou.cn/sitemap.xml" target="_blank">xml</a>
          </div>
          <div class="footer-r">
            <div
              class="footer-other-paths-one"
              v-for="(item, index) in footerInfo.imgs"
              :key="index"
            >
              <svg-icon :url="item.src" />
              <div class="title center">{{ item.title }}</div>
            </div>
          </div>
        </div>
        <!-- <div style="width: 1200px;height: 1px;background: linear-gradient(270deg, rgba(255, 255, 255, 0) 0%, #FFFFFF 33%, #FFFFFF 72%, rgba(255, 255, 255, 0) 100%);
    opacity: 0.23;margin: 36px auto 20px;"></div> -->
        <div @click="toLink" class="banquan">
          鲁 ICP 备2023024809号 山东十角兽网络科技有限公司 版权所有
        </div>
      </div>
      <div v-else class="m-footer-container">
        <div class="footer-r">
          <svg-icon :url="footerInfo.logo" class-name="logo" />
          <div
              class="footer-r-base"
              v-for="(item, index) in footerInfo.base"
              :key="index"
            >
              <span class="footer-r-base-title">{{ item.key }}</span>
              <span class="footer-r-base-desc">{{ item.value }}</span>
          </div>
          <a class="footer-r-xml" href="https://www.shijiaoshou.cn/sitemap.xml" target="_blank">xml</a>
        </div>
        <div class="footer-l">
          <div
            class="m-footer-other-paths-one"
            v-for="(item, index) in footerInfo.imgs"
            :key="index"
          >
            <svg-icon :url="item.src" class="way" />
            <div class="title center">{{ item.title }}</div>
          </div>
        </div>
        <div
          class="icp_box"
          @click="toLink"
        >
          <i></i>鲁 ICP 备2023024809号 山东十角兽网络科技有限公司 版权所有
        </div>
      </div>
      <!-- <div v-if="isMobile" style="width: 100%;
      height: 1px;
      background: linear-gradient(270deg, rgba(255, 255, 255, 0) 0%, #FFFFFF 30%, #FFFFFF 71%, rgba(255, 255, 255, 0) 100%);
      opacity: 0.23;"></div> -->
      
    </template>
  </div>
</template>

<script>
import { footerInfo } from '@/config'
import checkIsMobile from '@/mixin/checkIsMobile'

export default {
  name: 'Footer',
  mixins: [checkIsMobile],
  computed: {
    footerInfo() {
      return footerInfo
    },
  },
  methods: {
    toLink() {
      window.location.href = 'https://beian.miit.gov.cn/'
    },
  },
}
</script>

<style scoped lang="less">
.footer-container {
  height: 363px;
  background: linear-gradient(200deg, #023176 0%, #0a1265 18%, #060b4c 100%);
  padding-top: 70px;
  box-sizing: border-box;
  position: relative;
  z-index: 2;
  .footer {
    width: 1024px;
    display: flex;
    margin-bottom: 52px;
    &-l {
      flex: 1;
      .logo {
        margin-bottom: 28px;
        max-width: 140px;
      }
      &-base {
        margin-bottom: 12px;
        &-title {
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #ffffff;
          line-height: 20px;
          opacity: 0.5;
        }
        &-desc {
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #ffffff;
          line-height: 20px;
          opacity: 0.5;
        }
      }
      &-xml{
        color: #060b4c;
        // color: #ffffff;
        opacity: 0.5;
      }
    }
    &-r {
      display: flex;
      img {
        max-width: 135px;
        margin-bottom: 26px;
      }
      .title {
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #ffffff;
        opacity: 0.5;
      }
    }
  }
  .footer-other-paths-one:last-child {
    margin-left: 32px;
  }
  .banquan {
    width: 100%;
    text-align: center;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #ffffff;
    opacity: 0.5;
    cursor: pointer;
  }
}
.m-footer-container {
  width: 750px;
  // height: 958px;
  background: linear-gradient( 200deg, #023176 0%, #0A1265 18%, #060B4C 100%);
  border-radius: 0px 0px 0px 0px;
  padding-top: 136px;
  padding-bottom: 58px;
  .footer-l {
    text-align: center;
    
    .title {
      font-size: 28px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #ffffff;
      opacity: 0.5;
    }
  }
  .footer-r {
    text-align: center;
    .logo {
      display: block;
      margin: 0 auto;
      width: 280px;
      height: 90px;
      margin-bottom: 70px;
    }
    &-base {
      margin-bottom: 30px;
      &-title {
        font-size: 28px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #ffffff;
        line-height: 20px;
        opacity: 0.5;
      }
      &-desc {
        font-size: 28px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #ffffff;
        line-height: 20px;
        opacity: 0.5;
      }
    }
    &-xml{
      color: #060b4c;
      // color: #ffffff;
      opacity: 0.5;
    }
  }
  .m-footer-other-paths-one {
    flex: 1;
    .way {
      display: block;
      margin: 0 auto;
      width: 206px;
      height: 206px;
      margin-bottom: 32px;
    }
    .title {
      font-size: 28px;
      font-family: AlibabaPuHuiTiR;
      color: rgba(255, 255, 255, 0.64);
      line-height: 34px;
    }
  }
  .icp_box{
    font-family: PingFang SC, PingFang SC;
    font-weight: 400;
    font-size: 24px;
    color: #FFFFFF;
    line-height: 29px;
    opacity: 0.3;
    text-align: center;
    padding-top: 80px;
  }
}

</style>
